<template>
  <div id="lineageTracking" class="container-fluid">
    <div class="row">
      <div class="col col-10">
        <p>
          The Variant tracking viewer shows time changes in number of
          observations (sequenced genomes) of variants (lineages, mutations or
          their combinations) of SARS-CoV-2 in a selected geographical region.
          The tables include variants with at least 50 genomes sequenced in a
          region over the entire analyzed time span. This number is shown in the
          "Total" column. To show relative increase or decrease of prevalence of
          variants, data was divided in 14-day bins (counting only days with
          sequenced genomes) and normalized to take into account fluctuations in
          sequencing rate and differences in total prevalence of variants.
        </p>
        <p>
          For example, relative growth of 1 means that the number of
          observations of a variant in a 14-day bin was exactly as expected based
          on its total prevalence and the total number of genomes sequenced in
          that 14-day bin, 2 means that it was 2 times higher than expected, and
          0.5 - that it was 2 times lower than expected. The "Recent growth"
          column shows the average value (relative growth or decrease) from the
          most recent three 14-day bins. All the tables are based on complete
          genomes with less than 5% of undetermined nucleotides. Still, lack of
          specific mutations may be due to the presence of undetermined
          nucleotides. The counts from the most recent period are usually
          undercounted and noisy due to the reporting delay.
        </p>
        <br />
        <b>Last updated on {{ lastUpdated }}.</b>
      </div>
    </div>
    <hr />

    <form>
      <div class="form-row">
        <div class="col">
          <label for="region">Region/Continent</label>
          <select class="form-control" id="region" name="region" size="1" v-model="region" @change="setRegion({})">
            <option v-for="r in regions" :key="r" :value="r">
              {{ r }}
            </option>
          </select>
        </div>
        <div class="col">
          <label for="country">Region/Country</label>
          <select class="form-control" id="country" name="country" size="1" v-model="country" @change="setCountry({})">
            <option v-for="r in countries" :key="r" :value="r">
              {{ r }}
            </option>
          </select>
        </div>
        <div class="col">
          <label for="currentFile">State/Dataset</label>
          <select :enabled="country != null" class="form-control" id="currentFile" name="currentFile" size="1"
            v-model="currentFile" @change="setLineageFile({})">
            <option v-for="f in regionFiles" :key="f.path" :value="f.path">
              {{ f.label }}
            </option>
          </select>
        </div>
      </div>
    </form>

    <div v-if="error.hasError" class="row m-3 alert alert-danger" role="alert">
      {{ error.text }}
    </div>

    <div v-if="testData.length === 0" class="row m-3 alert alert-warning" role="alert">
      This dataset is empty. Pease try another one.
    </div>

    <div v-if="!error.hasError">
      <div v-if="currentFile">
        <div class="row">
          <div class="col col-6">
            <ul class="nav nav-pills my-3" id="tracker-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active px-3" id="tracker-table-tab" data-toggle="pill" href="#tracker-table"
                  role="tab" aria-controls="tracker-table" aria-selected="true">Table</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link px-3" id="tracker-chart-tab" data-toggle="pill" href="#tracker-chart" role="tab"
                  aria-controls="tracker-chart" aria-selected="false">Chart</a>
              </li>
            </ul>
          </div>
          <div class="col mt-3">
            <div class="float-right">
              <a _target="blank" class="btn btn-small btn-primary text-white" v-bind:href="dataFolder + currentFile"
                title="Download raw data">
                <span class="align-middle material-icons">save_alt</span>
                <span class="align-middle">&nbsp;Download&nbsp;raw&nbsp;data</span>
              </a>
            </div>
          </div>
        </div>
        <div class="tab-content" id="tracker-tabContent">
          <div class="tab-pane fade show active" id="tracker-table" role="tabpanel" aria-labelledby="tracker-table-tab">
            <ColorCodedTable :tableData="testData" :numRowHeaders="numRowHeaders" :rowHeaderFormat="rowHeaderFormat"
              :centralColorPoint="1" :formatVersion="formatVersion" v-on:vttable-sorted="sortedBy = $event">
            </ColorCodedTable>
          </div>
          <div class="tab-pane fade" id="tracker-chart" role="tabpanel" aria-labelledby="tracker-chart-tab">
            <BarChart3d :tableData="testData" :numRowHeaders="2" :sortBy="sortedBy"></BarChart3d>
          </div>
        </div>
      </div>
      <div v-else class="alert alert-warning">
        Please select data to display
      </div>
      <div class="mb-4 pb-4">
        <small class="text-muted">Version: {{ formatVersion }}.</small>
        <br /><br /><br />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    ColorCodedTable: () => import("../components/ColorCodedTable"),
    BarChart3d: () => import("../components/BarChart3d"),
  },
  mounted() {
    const self = this;
    this.loadMainData().then(function () {
      self.setFromRoute();
    });
  },
  data: function () {
    return {
      testData: { headers: [], data: [], range: [] },
      currentFile: "",
      formatVersion: "v2",
      defaultVersion: "v1",
      dataFiles: [],
      regions: [],
      region: "",
      country: null,
      numRowHeaders: 2, // for lineage_s_mut should be 3
      rowHeaderFormat: undefined, //  rowHeaderFormat  "String with $val" $val will be replaced by value
      lastUpdated: "04-25-2021",
      defaultDataSet: "World.lineages.tsv", //"North_America~USA.lineages.tsv",
      sortedBy: 0,
      error: { hasError: false, text: "" },
    };
  },
  computed: {
    dataFolder: function () {
      return "./mutations/" + this.formatVersion + "/variants_tracking/";
    },
    countries: function () {
      if (!this.dataFiles || this.dataFiles.length === 0) {
        return [];
      }
      const self = this;
      const filtered = self.dataFiles.filter(
        (item) => item.locLevels[0] === self.region
      );

      return [...new Set(filtered.map((item) => item.locLevels[1]))];
    },
    regionFiles: function () {
      const self = this;
      const res = self.dataFiles.filter((f) => {
        if (f.locLevels[0] === self.region && f.locLevels[1] === self.country)
          return f;
      });
      return res;
    },
  },

  watch: {
    $route(to) {
      if (to.name != "LineageTracking") return;
      // eslint-disable-next-line no-console
      // console.log(`LT: Navigating from ${from.name} to ${to.name}`);
      this.setFromRoute();
    },
  },
  methods: {
    async loadMainData() {
      let self = this;
      await window.d3
        .text(self.dataFolder + "updated")
        .then(function (results) {
          self.lastUpdated = results;
        });
      await window.d3
        .json(self.dataFolder + "list.json")
        .then(function (results) {
          self.dataFiles = results;

          self.dataFiles.forEach((f) => {
            if (f.locLevels.length < 2) f.locLevels[1] = f.locLevels[0];
          });

          self.regions = [...new Set(results.map((item) => item.locLevels[0]))]; // [ 'A', 'B']
        });
    },
    async setFromRoute() {
      const self = this;
      // eslint-disable-next-line no-console
      console.log(this.$route.params.file);

      const urlFile = this.$route.params.file ? this.$route.params.file : "*";

      let version = this.$route.params.v
        ? this.$route.params.v
        : this.defaultVersion;
      version =
        version === "v1" || version === "v2" ? version : this.defaultVersion;

      // if version has changed have to reload file list and lust update date
      if (this.formatVersion != version) {
        // eslint-disable-next-line no-console
        console.log(`Switching versions ${this.formatVersion} -> ${version}`);
        this.formatVersion = version;
        await self.loadMainData();
      }

      const file =
        urlFile != "*"
          ? decodeURIComponent(urlFile) + ".tsv"
          : this.defaultDataSet;

      //check that default file is there
      const filtered = self.dataFiles.filter((f) => f.path === file);

      if (filtered.length > 0) {
        self.region = filtered[0].locLevels[0];
        self.country = filtered[0].locLevels[1];
        self.currentFile = filtered[0].path;
      } else {
        self.region = self.dataFiles[0].locLevels[0]; //"North_America";
        self.country = self.dataFiles[0].locLevels[1]; //"USA";
        self.currentFile = self.dataFiles[0].path;
      }

      self.loadTable(self.currentFile);
    },
    setLineageFile() {
      const f = this.currentFile
        ? this.currentFile.substring(0, this.currentFile.length - 4)
        : this.defaultDataSet.substring(0, this.defaultDataSet.length - 4);
      // await
      this.$router
        .push({
          path: `/lineagetracking/${this.formatVersion}/${encodeURIComponent(
            f
          )}`,
        })
        .catch(() => { });
    },
    clear() {
      this.error = { hasError: false, text: "" };
      this.testData = { headers: [], data: [], range: [] };
    },
    loadTable(file) {
      if (!file) return;
      // load test data and show table
      // data format for input:
      // line 1 -  header
      // line 2 - total genome counts per time period
      // line 2+ - counts for each lineage
      // last column should not be sent to the viewer
      let self = this;
      self.clear();
      self.rowHeaderFormat = "none";
      window.d3.text(self.dataFolder + file).then(function (results) {
        let data = { headers: [], data: [], range: [] };
        if (results.startsWith("<")) {
          // no such file, vue returns html in this case
          self.error = {
            hasError: true,
            text: "No data file found on server. Please try another set.",
          };

          return;
        }
        let lines = results.split("\n");

        let totalGenomes = [];

        // lineage spike mutations combinations -> LINEAGE:MUT1 MUT2
        // lineages - lineages only, spike?
        // mutations - all proteins -> PROTEIN_MUT1
        // spike mutations  - spike only -> MUT1
        // spike mutations combinations - spike only -> MUT1 MUT2 MUT3

        const fileLower = file.toLowerCase();

        const idxL = fileLower.indexOf("lineage");
        const idxS = fileLower.indexOf("spike_");

        if (idxL > 0) {
          self.rowHeaderFormat = idxS > 0 ? "linSpikeCombo" : "lineage";
        } else {
          const idxC = fileLower.indexOf("combination");
          const idxM = fileLower.indexOf("mutation");

          if (idxS > 0) {
            // spike mutations
            self.rowHeaderFormat = idxC > 0 ? "spikeMutCombo" : "spikeMut";
          } else {
            self.rowHeaderFormat = idxM > 0 ? "mutation" : "none";
          }
        }
        // eslint-disable-next-line no-console
        console.log(` File: ${file} : format: ${self.rowHeaderFormat}`);

        lines.forEach((l, idx) => {
          l = l.trim();
          if (l.length > 0) {
            let c = l.split("\t");

            if (idx === 0) { // header row
              let h = 0;
              data.headers.push({ key: h, label: c[0] });
              if (self.rowHeaderFormat === "linSpikeCombo") {
                self.numRowHeaders = 3;
                h++;
                data.headers.push({ key: h, label: c[h] });
              } else {
                self.numRowHeaders = 2;
              }
              let totalHeader = c.pop();
              data.headers.push({ key: ++h, label: totalHeader });

              for (let i = h; i < c.length; i++) {
                data.headers.push({ key: i + 1, label: c[i] });
              }

              // move last column to the second place

              data.headers.push({
                key: data.headers.length,
                label: "Recent growth",
              });
            } else if (idx === 1) { //totals row
              c.shift(); // we don't need row header
              self.rowHeaderFormat === "linSpikeCombo" && c.shift(); 
              c.forEach((v) => {
                totalGenomes.push(+v); // convert to number
              });
            } else {
              let rowHeader = c.shift(); //"lineage"

              let numC = [rowHeader];

              if (self.rowHeaderFormat === "linSpikeCombo") {
                let mutHeader = c.shift();
                numC.push(mutHeader);
              }

              let total4Lineage = +c.pop();
              let normalized = self.normalize(c, totalGenomes, total4Lineage);

              let min = Math.min(...normalized);
              let max = Math.max(...normalized);

              if (data.range[0] === undefined) {
                data.range[0] = min;
                data.range[1] = max;
              } else {
                data.range[0] = data.range[0] > min ? min : data.range[0];
                data.range[1] = data.range[1] > max ? data.range[1] : max;
              }

              numC.push(total4Lineage);
              normalized.forEach((v) => {
                numC.push(+v); // convert to number
              });
              data.data.push(numC);
            }
          }
        });
        self.testData = data;
      });
    },
    normalize(row, totalGenomes, total4Lineage) {
      /** LJ:
       * it basically takes each number from the green part (raw genome counts)
       * divides it by the total number of genomes in a lineage
       * (here column L; note: it is just a sum of a row)
       * and by the corresponding total number of genomes in that date (week really)
       * which is provided at the bottom of the green area
       * (here row 121 – note: it is _not_ a sum of a column)
       * and, finally, multiples the result by the total number of genomes
       * sequenced in the entire period (here $L$121).
       * */
      let result = [];

      let total = totalGenomes[totalGenomes.length - 1];

      row.forEach((v, i) => {
        let n = (v * total) / (total4Lineage * totalGenomes[i]);
        isNaN(n) && (n = 0);
        result.push(+n.toFixed(2));
      });

      let l = result.length;
      result.push(
        ((result[l - 1] + result[l - 2] + result[l - 3]) / 3).toFixed(2)
      );
      return result;
    },
    setRegion() {
      this.clear();
      this.country = null;
      this.currentFile = null;
      if (this.countries.length === 1) {
        this.country = this.countries[0];
        this.setCountry();
      }
    },
    setCountry() {
      this.clear();

      if (this.regionFiles.length === 1) {
        this.currentFile = this.regionFiles[0].path;
        this.setLineageFile();
      }
    },
  },
};
</script>