<template>
  <div id="combinationsViewer" class="container-fluid mb-4">
    <form class="border p-2 mb-3">
      <div class="row">
        <div class="col mb-3">
          The Lineages/Mutations viewer displays global distributions of all
          combinations of protein mutations in SARS-CoV-2 pango lineages and
          vice versa. The distribution of all combinations of mutations in
          lineages can be displayed by selecting only a protein name. This data
          can be filtered using “Lineage” and “Mutations” fields. Mutations can
          be entered as specific substitutions (e.g., Q8A R20S S25G) or as
          positions (e.g., 8 20 25). In the latter case all mutations at these
          positions will be shown. The filtering by mutations can be modified
          using “Exact match” checkbox. All the tables are based on complete,
          with less than 5% of undetermined nucleotides. The genomes which
          were not flagged as high-coverage by GISAID were considered "low-quality".
          The % of such genomes per each lineage or combination of
          mutations is shown in the left-most columns.
          The lack of founder mutations is often caused by low-quality of genomic data.
        </div>
      </div>
      <div class="form-group py-2 row">
        <div class="col col-10">
          <div class="form-group row">
            <label
              for="searchTermLineage"
              class="col-sm-3 col-form-label col-form-label-sm"
              >Lineage
            </label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control form-control-sm"
                id="searchTermLineage"
                v-model="searchTermLineage"
                placeholder="enter lineage name OR leave empty"
              />
            </div>
          </div>
          <div class="form-group row">
            <label
              for="searchTermProtein"
              class="col-sm-3 col-form-label col-form-label-sm"
              >Protein
            </label>
            <div class="col-sm-9">
              <select
                class="form-control form-control-sm"
                id="searchTermProtein"
                v-model="searchTermProtein"
              >
                <option
                  v-for="p in proteins[formatVersion]"
                  v-bind:value="p.key"
                  v-bind:key="p.key"
                >
                  {{ p.key }}, {{ p.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label
              for="searchTermMut"
              class="col-sm-3 col-form-label col-form-label-sm"
              >Mutations</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control form-control-sm"
                id="searchTermMut"
                v-model="searchTermMut"
                placeholder="enter one or more mutations OR leave empty"
              />
              <div class="col form-group pt-1">
                <div class="row">
                  <div class="form-check small col">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="exactMutSearchCheck"
                      v-model="exactMutSearch"
                    />
                    <label class="form-check-label" for="exactMutSearchCheck">
                      Search for exact mutation combination
                    </label>
                  </div>
                  <div class="col-auto mt-2">
                    <button
                      type="submit"
                      class="btn btn-primary mb-2"
                      @click="navigate()"
                      :disabled="!haveValidSearchTerms()"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!clean && !haveValidSearchTerms()" class="row text-danger">
            <div class="col">
              Check your input. At least one of the search terms must be 3 or
              more characters long.
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row" v-if="searchResults.length > 0">
      <div class="col col-6">
        <div class="col">
          <button
            type="submit"
            class="btn btn-primary mb-2 btn-sm"
            @click="download()"
          >
            Download all results
          </button>
        </div>
        <div class="col small">
          Showing rows {{ 1 + currentPage * pageSize }} -
          {{ Math.min(searchResults.length, (1 + currentPage) * pageSize) }} out
          of
          {{ searchResults.length }}
        </div>
      </div>
      <div class="col col-6">
        <div class="row">
          <div class="col">
            <nav
              v-if="numPages > 1"
              aria-label="Search results page navigation"
            >
              <ul class="pagination pagination-sm justify-content-end">
                <li class="page-item">
                  <span class="page-link" v-on:click="page(0)"
                    >&laquo; First</span
                  >
                </li>
                <li class="page-item mr-2">
                  <span class="page-link" v-on:click="page(currentPage - 1)">
                    &lt; Previous</span
                  >
                </li>

                <li class="page-item" v-if="currentPage > 1">
                  <span class="page-link" v-on:click="page(currentPage - 2)">{{
                    currentPage - 1
                  }}</span>
                </li>
                <li class="page-item" v-if="currentPage > 0">
                  <span class="page-link" v-on:click="page(currentPage - 1)">{{
                    currentPage
                  }}</span>
                </li>

                <li class="page-item active">
                  <span class="page-link">{{ currentPage + 1 }}</span>
                </li>
                <li class="page-item" v-if="currentPage < numPages - 1">
                  <span class="page-link" v-on:click="page(currentPage + 1)">{{
                    currentPage + 2
                  }}</span>
                </li>
                <li class="page-item" v-if="currentPage < numPages - 2">
                  <span class="page-link" v-on:click="page(currentPage + 2)">{{
                    currentPage + 3
                  }}</span>
                </li>

                <li class="page-item ml-2">
                  <span class="page-link" v-on:click="page(currentPage + 1)"
                    >Next &gt;</span
                  >
                </li>
                <li class="page-item mr-2">
                  <span class="page-link" v-on:click="page(numPages)"
                    >Last &raquo;</span
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div class="col small">
          <div class="float-right form-inline">
            Display
            <select
              class="form-control form-control-sm"
              id="topSizeSelect"
              v-model="pageSize"
              @change="resetPages()"
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>150</option>
              <option>200</option>
            </select>
            results per page.
          </div>
        </div>
      </div>
    </div>
    <div class="row ml-4" v-if="!searching && searchResults.length === 0">
      No results. Try modifying search parameters.
    </div>

    <div v-if="searchResults.length > 0">
      <div
        class="container-fluid m-1 py-3 small border border-primary bg-light"
        v-if="masterMutations.length > 0"
      >
        <div class="row mx-2 form-inline">
          <b>Display results as:</b>
          <div class="form-check form-check-inline pl-2">
            <input
              class="form-check-input"
              type="radio"
              name="displayAs"
              id="displayAsPlain"
              v-model="displayAs"
              value="plain"
            />
            <label class="form-check-label" for="displayAsPlain"
              >plain text</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="displayAs"
              id="displayAsDiffText"
              v-model="displayAs"
              value="difftext"
              :disabled="masterMutations.length === 0"
            />
            <label class="form-check-label" for="displayAsDiffText"
              >diff.text</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="displayAs"
              id="displayAsMatrix"
              v-model="displayAs"
              value="diffmatrix"
              :disabled="masterMutations.length === 0"
              @change="recalcTopMutations()"
            />
            <label class="form-check-label" for="displayAsMatrix"
              >diff. matrix</label
            >
          </div>
        </div>
        <div class="row p-1" v-if="displayAs != 'plain'">
          <div class="col col-8 form-inline">
            <div class="secondary-text">
              Comparing to the mutations in the top
              <select
                class="form-control form-control-sm"
                id="topSizeSelect"
                v-model="topSize"
                @change="recalcTopMutations()"
              >
                <option>1</option>
                <option>5</option>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
              combinations: <br />[<span
                class="master"
                v-for="m in masterMutations"
                v-bind:key="m"
                >{{ m }}
              </span>
              ]
            </div>
          </div>
          <div class="col col-4 border-left">
            <p>
              Mutations are colored as following: <br /><span
                class="mutins border"
                >extra mutation present in this combination</span
              >
              <br /><span class="mutdel border"
                >mutation missing in this combination</span
              >
            </p>
          </div>
        </div>

        <div class="row" v-if="displayAs === 'diffmatrix'">
          <div class="col">
            <p>
              <a
                class="btn btn-link btn-sm ml-1 small muted"
                data-toggle="collapse"
                href="#advOptionsCollapse"
                role="button"
                aria-expanded="false"
                aria-controls="advOptionsCollapse"
              >
                Advanced options
              </a>
            </p>
            <div class="collapse" id="advOptionsCollapse">
              <div class="row m-3 form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="showFullMatrix"
                  id="showFullMatrixChk"
                  @change="recalcTopMutations()"
                />
                <label class="form-check-label" for="showFullMatrixChk">
                  Show combination of all mutations on the page in matrix.
                </label>

                <small id="showFullMatrixHelp" class="form-text text-muted">
                  Disabling this option will create smaller matrix showing only
                  mutations found in the selected top combinations.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--- results table --->
      <div class="row">
        <div class="col col-12 small table-wrapper">
          <p class="small text-muted mt-2">
            *You can scroll table horizontally if it does not fit on the screen.
          </p>
          <!-- text table -->
          <table
            class="table table-sm table-hover table-striped mt-2"
            v-if="displayAs != 'diffmatrix'"
          >
            <thead>
              <tr>
                <th>#</th>
                <th v-for="(h, i) in columnHeaders" v-bind:key="i">
                  {{ h }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, i) in pagedTable" v-bind:key="i">
                <td>{{ currentPage * pageSize + i + 1 }}</td>
                <td
                  v-for="(d, idx) in r"
                  v-bind:key="idx"
                  :class="isNaN(d) ? '' : 'text-right'"
                >
                  <span
                    v-if="idx === 'Mutations'"
                    v-html="formattedMutations(d, i)"
                  >
                  </span>
                  <span v-else>{{ formattedValue(d) }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- matrix table -->
          <table class="table table-sm table-hover table-striped mt-2" v-else>
            <thead>
              <tr>
                <th>#</th>
                <th
                  class="rotated"
                  v-for="h in headersMutationsSet.values()"
                  v-bind:key="h"
                  :class="masterMutations.indexOf(h) > -1 ? 'master' : ''"
                >
                  <div>
                    <span>{{ h }}</span>
                  </div>
                </th>
                <th v-for="(h, i) in columnHeaders" v-bind:key="i">
                  <span v-if="h != 'Mutations'">{{ h }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, i) in pagedTable" v-bind:key="i">
                <td>{{ currentPage * pageSize + i + 1 }}</td>
                <td
                  v-for="h in headersMutationsSet.values()"
                  v-bind:key="h"
                  class="p-0 m-0 border-right text-center align-middle cell"
                  :class="
                    rowContainsMut(r, h, currentPage * pageSize + i) === 1
                      ? 'mutdel'
                      : rowContainsMut(r, h, currentPage * pageSize + i) === -1
                      ? 'mutins'
                      : ''
                  "
                >
                  <span
                    v-if="
                      rowContainsMut(r, h, currentPage * pageSize + i) === 1
                    "
                    >x</span
                  >
                  <span
                    v-else-if="
                      rowContainsMut(r, h, currentPage * pageSize + i) === -1
                    "
                    >v</span
                  >
                  <span v-else></span>
                </td>
                <td
                  v-for="(d, idx) in r"
                  v-bind:key="idx"
                  :class="isNaN(d) ? '' : 'text-right'"
                >
                  <span v-if="idx != 'Mutations'">
                    {{ formattedValue(d) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="mb-4 pb-4">
      <small class="text-muted">Version: {{ formatVersion }}.</small>
      <br /><br /><br />
    </div>
  </div>
</template>
        
<script>
export default {
  components: {},
  mounted() {
    this.searching = true;
    this.setFromRoute();
  },
  data: function () {
    return {
      clean: true,
      searching: true,
      formatVersion: "v2",
      defaultVersion: "v1",
      displayAs: "plain", // 'difftext', diffmatrix'
      displayAsAllowed: ["plain", "difftext", "diffmatrix"], // allowed values for displayAs
      fullTable: [],
      columnHeaders: [],
      searchTermLineage: "",
      searchTermMut: "",
      searchTermProtein: "Spike",
      lastProtein: "", //
      queryLineage: "",
      queryMut: "",
      lineageNames: new Set(),
      combinations: new Set(),
      pageSize: 100,
      currentPage: 0,
      numPages: 0,
      searchResults: [],
      exactMutSearch: false,
      sortKey: null,
      reverse: true,
      proteins: {
        /// will have to use consistend name later
        v1: [
          { key: "NSP1", label: "Host translation inhibitor" },
          { key: "NSP2", label: "Non-structural protein 2" },
          { key: "NSP3", label: "Papain-like protease" },
          { key: "NSP4", label: "Non-structural protein 4" },
          { key: "NSP5", label: "3C-like proteinase" },
          { key: "NSP6", label: "Non-structural protein 6" },
          { key: "NSP7", label: "Non-structural protein 7" },
          { key: "NSP8", label: "Non-structural protein 8" },
          { key: "NSP9", label: "Non-structural protein 9" },
          { key: "NSP10", label: "Growth factor-like peptide" },
          { key: "NSP11", label: "Non-structural protein 11" },
          { key: "NSP12", label: "RNA-directed RNA polymerase" },
          { key: "NSP13", label: "Helicase" },
          { key: "NSP14", label: "Proofreading exoribonuclease" },
          { key: "NSP15", label: "Uridylate-specific endoribonuclease" },
          { key: "NSP16", label: "2'-O-methyltransferase" },
          { key: "SPIKE", label: "Spike glycoprotein" },
          { key: "NS3", label: "Accessory protein 3a" },
          { key: "E", label: "Envelope small membrane protein" },
          { key: "M", label: "Membrane protein" },
          { key: "NS6", label: "Accessory protein 6" },
          { key: "NS7A", label: "Accessory protein 7a" },
          { key: "NS7B", label: "Accessory protein 7b" },
          { key: "NS8", label: "ORF8 protein" },
          { key: "N", label: "Nucleoprotein" },
        ],
        v2: [
          { key: "NSP1", label: "Host translation inhibitor" },
          { key: "NSP2", label: "Non-structural protein 2" },
          { key: "NSP3", label: "Papain-like protease" },
          { key: "NSP4", label: "Non-structural protein 4" },
          { key: "NSP5", label: "3C-like proteinase" },
          { key: "NSP6", label: "Non-structural protein 6" },
          { key: "NSP7", label: "Non-structural protein 7" },
          { key: "NSP8", label: "Non-structural protein 8" },
          { key: "NSP9", label: "Non-structural protein 9" },
          { key: "NSP10", label: "Growth factor-like peptide" },
          { key: "NSP11", label: "Non-structural protein 11" },
          { key: "NSP12", label: "RNA-directed RNA polymerase" },
          { key: "NSP13", label: "Helicase" },
          { key: "NSP14", label: "Proofreading exoribonuclease" },
          { key: "NSP15", label: "Uridylate-specific endoribonuclease" },
          { key: "NSP16", label: "2'-O-methyltransferase" },
          { key: "SPIKE", label: "Spike glycoprotein" },
          { key: "NS3", label: "Accessory protein 3a" },
          { key: "E", label: "Envelope small membrane protein" },
          { key: "M", label: "Membrane protein" },
          { key: "NS6", label: "Accessory protein 6" },
          { key: "NS7A", label: "Accessory protein 7a" },
          { key: "NS7B", label: "Accessory protein 7b" },
          { key: "NS8", label: "ORF8 protein" },
          { key: "N", label: "Nucleoprotein" },
          { key: "NS10", label: "ORF10 protein" },
        ],
      },
      parentLineages: ["delta.merged", "ba.1.merged","ba.2.merged"], // should match name of the file with the list of sublioneages, keep them lowercase
      loadedLineageLists: {}, // key is the parent lineage name, value is array of sublineages names
      masterMutations: [], // list of mutations from the top of the search, or could be later provided by user or us?
      masterMutationsSet: new Set(), // set for the above, to avoid recalculation for each table row
      headersMutationsSet: new Set(), // mutations to show as table header
      // allButTopMutations: [], // list all mutations
      topSize: 1, // number of top combinastion to build a master
      showFullMatrix: true,
    };
  },
  computed: {
    pagedTable() {
      const start = this.currentPage * this.pageSize;
      const end = (this.currentPage + 1) * this.pageSize;
      return this.searchResults.slice(start, end);
    },
  },
  watch: {
    $route(to) {
      if (to.name != "CombinationsViewer") return;
      this.searching = true;
      this.setFromRoute();
    },
  },
  methods: {
    async navigate() {
      const self = this;
      self.currentPage = 0;
      const p = self.searchTermProtein.trim();
      const l = self.searchTermLineage.trim().replaceAll(" ", "_");
      const m = self.searchTermMut.trim().replaceAll(" ", "_");
      const e = self.exactMutSearch ? "true" : "false";

      self.clean = false;
      self.searching = true;

      await this.$router
        .push({
          query: {
            l: encodeURIComponent(l),
            p: encodeURIComponent(p),
            m: encodeURIComponent(m),
            e: e,
            // display params
            v: encodeURIComponent(self.displayAs),
            n: encodeURIComponent(self.pageSize),
            pg: encodeURIComponent(self.currentPage),
            f: encodeURIComponent(self.formatVersion),
          },
        })
        .catch(() => {});
    },
    async loadProteinTable(protein) {
      if (!protein || protein.length === 0) return;
      // eslint-disable-next-line no-console
      console.log(`Loading data for ${protein}`);
      let self = this;
      let dataFile = `./mutations/${self.formatVersion}/mutcombos/${protein}.tsv`;

      window.d3.tsv(dataFile).then(function (results) {
        self.lastProtein = protein;
        self.fullTable = results;
        self.lineageNames.clear();
        self.combinations.clear();
        self.columnHeaders = Object.keys(results[0]);
        self.fullTable.forEach((row) => {
          self.lineageNames.add(row["Lineage"]);
          self.combinations.add(row["Mutations"]);
        });

        // eslint-disable-next-line no-console
        console.log(`Loaded data for ${protein}:
                    ${self.lineageNames.size} lineages added
                    ${self.combinations.size} combinations added`);

        self.setFromRoute();
      });
    },
    async setFromRoute() {

      let version = this.$route.query.f
        ? this.$route.query.f
        : this.defaultVersion;
      version =
        version === "v1" || version === "v2" ? version : this.defaultVersion;
      this.formatVersion = version;

      this.pageSize = this.$route.query.n
        ? +decodeURIComponent(this.$route.query.n)
        : 100;
      this.currentPage = this.$route.query.pg
        ? +decodeURIComponent(this.$route.query.pg)
        : 0;

      let v =
        this.$route.query.v === undefined
          ? "plain"
          : decodeURIComponent(this.$route.query.v);
      this.displayAs = this.displayAsAllowed.indexOf(v) >= 0 ? v : "plain";

      // react to route changes...
      let l =
        this.$route.query.l === undefined ? "BA.5" : this.$route.query.l;

      this.searchTermLineage = decodeURIComponent(l)
        .replaceAll("_", " ")
        .trim();

      let p = this.$route.query.p || "S";
      this.searchTermProtein = decodeURIComponent(p).toUpperCase();

      let m = this.$route.query.m || "";
      this.searchTermMut = decodeURIComponent(m).replaceAll("_", " ").trim();
      this.exactMutSearch = this.$route.query.e === "true" ? true : false;
      this.clean = false;

      if (this.parentLineages.includes(this.searchTermLineage.toLowerCase())) {
        // have to load sublineages list
        await this.loadSublineages(this.searchTermLineage.toLowerCase());
      }

      // a bit of handwaiving
      if (p === "S"){
        p = (version === "v1") ? "SPIKE" :"S";
      }


      p = p.toUpperCase();
      if (p !== "" && p !== this.lastProtein) {
        // user selected other protein, have to load data
        await this.loadProteinTable(p);
        this.lastProtein = p;
      }

      await this.search();
      this.recalcTopMutations();
    },
    async search() {
      this.searchResults = await this.filterTable();
      this.numPages = Math.ceil(this.searchResults.length / this.pageSize);
    },
    haveValidSearchTerms() {
      return true;
    },
    sortBy: function (sortKey) {
      this.reverse = this.sortKey == sortKey ? !this.reverse : false;
      this.sortKey = sortKey;
    },
    page(p) {
      if (p < 0) this.currentPage = 0;
      else if (p >= this.numPages) this.currentPage = this.numPages - 1;
      else this.currentPage = p;
    },
    cleanInputString(input) {
      return input.replaceAll(",", " ").replace(/\s\s+/g, " ").trim();
    },
    async filterTable() {
      if (!this.haveValidSearchTerms()) return [];

      const self = this;

      const l = self.cleanInputString(self.searchTermLineage).toUpperCase();
      const m = self.cleanInputString(self.searchTermMut);
      const terms = m.length > 0 ? m.split(" ") : [];

      if (l.length === 0 && m.length === 0 && !self.exactMutSearch)
        return self.fullTable;

      let lineages = self.loadedLineageLists[l.toLowerCase()];
      if (!lineages) {
        lineages = l.length > 0 ? l.split(" ") : [];
      }

      const res = self.fullTable.filter((row) => {
        if (
          self.matchesLineages(row["Lineage"], lineages) &&
          self.matchesMutations(
            row["Mutations"].trim(),
            terms,
            self.exactMutSearch
          )
        ) {
          return row;
        }
      });
      this.recalcTopMutations(res);
      this.searching = false;
      // eslint-disable-next-line no-console
      console.log("Search completed");
      return res;
    },
    recalcTopMutations(filteredtable) {
      const res = filteredtable || this.searchResults;
      if (res.length === 0) return;
      let topMutations = new Set();
      const z = Math.min(this.topSize, res.length - 1);

      for (let i = 0; i < z; i++) {
        const rowMut = res[i].Mutations.split(" ");
        rowMut.forEach((x) => topMutations.add(x));
      }

      topMutations = this.sortMutationsByPosition(topMutations);
      this.masterMutations = [...topMutations];
      this.masterMutationsSet = topMutations;

      if (this.displayAs === "diffmatrix" && this.showFullMatrix) {
        let headMutations = new Set();
        const s = this.pagedTable.length - 1;
        for (let i = 0; i < s; i++) {
          const rowMut = this.pagedTable[i].Mutations.split(" ");
          rowMut.forEach((x) => headMutations.add(x));
        }
        this.headersMutationsSet = this.sortMutationsByPosition(headMutations);
      } else {
        this.headersMutationsSet = this.masterMutationsSet;
      }
    },
    async loadSublineages(parentLineage) {
      let self = this;
      const pl = parentLineage.toLowerCase();
      let list = self.loadedLineageLists[pl];

      if (!list || list.length == 0) {
        let dataFile = `./mutations/${self.formatVersion}/variants_tracking/${pl}.txt`;
        window.d3.text(dataFile).then(function (results) {
          self.loadedLineageLists[pl] = results.split("\n");
        });
      }
    },
    matchesLineages(value, terms) {
      if (terms.length === 0) return true;

      return terms.includes(value.toUpperCase());
    },
    matchesMutations(value, terms, exact) {
      return exact
        ? this.containsExact(value, terms)
        : this.containsAll(value, terms);
    },
    containsExact(value, terms) {
      //  exact search for an empty mut. string
      if (terms.length === 0 && value.length === 0) return true;
      //check if value contains only terms provided
      const values = value.split(" ");
      if (values.length != terms.length) return false;
      return this.containsAll(value, terms);
    },
    containsAll(value, terms) {
      let res = true;

      for (let t of terms) {
        if (isNaN(Number(t))) {
          // mutation
          if (!value.includes(t)) return false;
        } else {
          // position
          let regex = new RegExp(t + ":[a-zA-Z]");
          if (self.formatVersion === "v1") {
            regex = new RegExp("[a-zA-Z]" + t + "[a-zA-Z]");
          }

          if (!value.match(regex)) return false;
        }
      }

      return res;
    },
    sortMutationsArrByPosition(mutarr) {
      return mutarr.sort((a, b) => {
        const pa = a.match(/\d+/g);
        const pb = b.match(/\d+/g);
        return pa - pb;
      });
    },
    sortMutationsByPosition(mutSet) {
      const arr = Array.from(mutSet);
      const sorted = this.sortMutationsArrByPosition(arr);
      return new Set(sorted);
    },
    formattedValue(d) {
      if (isNaN(d)) return d;

      const n = +d; //Number(d.trim());
      const res = Number.isInteger(n) ? n : n.toFixed(3);
      return res;
    },
    formattedMutations(mutString) {
      if (this.masterMutations.length === 0 || this.displayAs === "plain")
        return mutString;

      // might be better to move this splitting to the initial loading to avoid recalculation?
      // but this function will be called only for the mutatioons on the current page, less computations.

      // mutations in this combination
      const mlist = mutString.split(" ");

      let masterOnly = this.masterMutations.filter((x) => !mlist.includes(x));
      let testOnly = mlist.filter((x) => !this.masterMutations.includes(x));

      // mutations in this AND master combination
      const joinedlist = this.sortMutationsByPosition(
        new Set([...this.masterMutationsSet, ...mlist])
      );

      let res = "";
      joinedlist.forEach((m) => {
        if (masterOnly.indexOf(m) >= 0) {
          res += `<span class="mutdel">${m}</span> `;
        } else if (testOnly.indexOf(m) >= 0) {
          res += `<span class="mutins">${m}</span> `;
        } else res += `${m} `;
      });
      return res;
    },
    rowContainsMut(row, mutation, i) {
      if (!row.Mutations) return 0; //

      const mlist = row.Mutations.split(" ");
      const inMaster = this.masterMutations.indexOf(mutation);
      const inRow = mlist.indexOf(mutation);

      if (inMaster < 0 && inRow >= 0) {
        return -1;
      } else if (inMaster >= 0 && inRow < 0) {
        return 1;
      } else if (i < this.topSize && inRow < 0) {
        return 1;
      } else return 0;
    },
    resetPages() {
      // TODO:  save first row on the page and set currentPage to include it?
      this.currentPage = 0;
      this.numPages = Math.ceil(this.searchResults.length / this.pageSize);
      this.recalcTopMutations();
    },
    download() {
      const text = window.d3.csvFormat(this.searchResults);
      const data = new Blob([text], { type: "text/plain" });
      this.saveBlob(data, "coronavirus3d-search-results.csv");
    },
    saveBlob(data, filename) {
      //TODO: this is a copy of function from 3DmolWrapper. better to move to some shared location
      try {
        window.saveAs(data, filename);
      } catch (e) {
        var url = window.URL.createObjectURL(data);
        window.open(url);
      }
    },
  },
};
</script>
<style>
/* mutation is in master comboo only*/
.mutdel {
  background-color: rgb(226, 169, 176);
  border-color: rgb(226, 169, 176);
}
/* mutation is in test combo only */
.mutins {
  background-color: rgb(171, 197, 223);
  border-color: rgb(171, 197, 223);
}

.table-wrapper {
  overflow-x: scroll;
}

/* matrix header styles */

.master {
  background-color: #ffc10759;
}

th.rotated {
  height: 140px;
  white-space: nowrap;
  padding: 0 !important;
}

th.rotated > div {
  transform: translate(0px, 0px) rotate(270deg);
  width: 20px;
}

th.rotated > div > span {
  padding: 5px 10px;
}

td.cell {
  padding: 0px;
  margin: 0px;
}
</style>